<template>
	<main class="page-container container">
		<div class="section__top" :class="isMobileSmall ? 'mb-10' : 'mb-30'">
			<div class="section__top-left">
				<AppText
					:size="isMobileSmall ? 18 : isMobile ? 22 : 26"
					weight="600"
					:line-height="isMobileSmall ? 22 : isMobile ? 28 : 34"
				>
					{{ $t("priceCriteriaForPublicProcurement") }}
				</AppText>
			</div>
			<div class="section__top-right"></div>
		</div>

		<div class="section__body" :class="isMobileSmall ? 'mb-30' : 'mb-60'">
			<div class="table-block secondary-table" :class="isMobileSmall ? 'mb-30' : 'mb-60'">
				<table>
					<thead>
						<tr>
							<th>{{ $t("purchaseType") }}</th>
							<th style="max-width: 300px">{{ $t("subjectOfProcurement") }}</th>
							<th style="max-width: 300px">{{ $t("budgetCustomers") }}</th>
							<th style="max-width: 300px">{{ $t("corporateCustomers") }}</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td style="max-width: 300px; background: #f3f8fc" rowspan="2">
								<AppText weight="600" size="14">{{ $t("cooperation") }}</AppText>
								(48 {{ $t("hours") }})
							</td>
							<td style="max-width: 300px">{{ $t("product") }}, {{ $t("workAndServices") }}</td>
							<td style="max-width: 300px" colspan="2">
								{{ $t("limitIsNotChecked") }}
							</td>
						</tr>

						<!-- <tr>
							<td style="max-width: 300px; background: #f3f8fc">
								<AppText weight="600" size="14">{{ $t("auction") }}</AppText>
								({{ $t("notLessThan5WorkingDays") }})
							</td>
							<td style="max-width: 300px">{{ $t("product") }}</td>
							<td style="max-width: 300px">
								<b>до 6 000 БРВ</b>
							</td>
							<td style="max-width: 300px"><b>до 25 000 БРВ</b></td>
						</tr> -->
						<!-- <tr>
							<td style="max-width: 300px; background: #f3f8fc">
								<AppText weight="600" size="14">{{ $t("selection") }}</AppText>
								({{ $t("notLessThan5WorkingDays") }})
							</td>
							<td style="max-width: 300px">{{ $t("product") }}, {{ $t("workAndServices") }}</td>
							<td style="max-width: 300px">
								<b>до 2 500 БРВ</b><br />
								(но не более <b>10 000 БРВ</b> в течение года по однотипным товарам)
							</td>
							<td style="max-width: 300px"><b>до 25 000 БРВ</b></td>
						</tr> -->
						<!-- <tr>
							<td style="max-width: 300px; background: #f3f8fc">
								<AppText weight="600" size="14">{{ $t("tender") }}</AppText>
								({{ $t("notLessThan30WorkingDays") }})
							</td>
							<td style="max-width: 300px">{{ $t("product") }}, {{ $t("workAndServices") }}</td>
							<td style="max-width: 300px">свыше 6 000 БРВ</td>
							<td style="max-width: 300px"><b>свыше 25 000 БРВ</b></td>
						</tr> -->
						<!-- <tr>
							<td style="max-width: 300px; background: #f3f8fc">
								<AppText weight="600" size="14">{{ $t("directContracts") }}</AppText>
							</td>
							<td style="max-width: 300px">{{ $t("product") }}, {{ $t("workAndServices") }}</td>
							<td style="max-width: 300px" colspan="2">
								<b>{{ $t("noLimitSet") }}</b>
							</td>
						</tr> -->
					</tbody>
				</table>
			</div>
		</div>
	</main>
</template>

<script>
import AppText from "../../components/shared-components/AppText";

export default {
	name: "index",
	components: { AppText },
};
</script>

<style lang="scss" scoped>
.secondary-table {
	text-align: center;
	background: #fafdff;

	th {
		background: var(--color-success2);
		color: white;
		text-align: center;
		padding: 22px;
		border-right: 1px solid #45bacb;
	}
}
</style>
